@import "~bootstrap/scss/bootstrap.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header.counter-navbar {
  min-height: 4rem;
  background-color: #11567c;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,.05), inset 0 -1px 0 rgba(0,0,0,.1);

  h1 {
    color: #ffffff;
  }
}

div#main {
  margin-top: 50px;

  div#counter-value {
    font-family: monospace;
    font-size: 10em;
    border: 1px solid gray;
    margin: 25px;
    width: 100%;
    text-align: center;
    border-radius: 20px;
  }
}
